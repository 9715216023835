<template>
  <router-view v-if="isComponentReady"></router-view>
</template>
<script>
export default {
  name: 'ImageRecognition',
  data() {
    return {
      isComponentReady: false,
    };
  },
  created() {
    this.$nextTick(() => {
      this.isComponentReady = true;
    });
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.isComponentReady = true;
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.isComponentReady = false;
    this.$nextTick(() => {
      this.isComponentReady = true;
      next();
    });
  },
};
</script>
