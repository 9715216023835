export const AppDefaultState = () => {
  return {
    config: {
      api: {
        baseURL: process.env.VUE_APP_API_BASE_URL,
      },
    },
    appbarConfig: {},
    defaultMessages: {},
    redirectTo: null,
    cookieConsentVersion: '',
    isOverlayVisible: false,
    isOverlayEnabled: true,
    isMenuOpened: false,
    showBottomBar: true,
    modalConfig: {},
    errorModalConfig: {},
    statusModalConfig: {},
    menuItems: {},
    currentMenuItems: {},
    overflow: 'auto',
    showModalOnFunPage: false,
    searchedText: '',
    customerDetail: {},
    customerPoints: null,
    cookieSettings: null,
    virtualVisit: {
      url: null,
    },
    addedToHomeScreenStatus: null,
    deviceType: null,
    doWinCardRefresh: null,
    marathonCardRefresh: false,
    location: '',
    qrResponse: {
      rackCount: null,
      customerName: '',
      customerCode: '',
    },
    racks: [],
    onCloseClick: false,
    otpCategoryStatuses: null,
    lastPosition: 0,
    contentTypes: [],
    categories: [],
    activeDowinCount: 0,
    qrViewStatus: false,
    isNPSSurveyFinished: false,
    changedPosToken: '',
    rosettes: [],
    popups: {},
    feedTaskDetail: {},
    isRosetteRewardStatu: false,
    currentMarathon: null,
    activeNotificationCount: 0,
    notificationsVisibility: false,
  };
};
