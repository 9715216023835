<template>
  <div class="sales-tracking">
    <div class="sales-tracking-top">
      <div class="family-sales">
        <div class="title">Aile Satış Oranı</div>
        <div class="sales-pie-wrapper">
          <BrandPieChart
            class="pie-chart"
            :chartData="familySaleRate"
            :chartOptions="familySaleOptions"
          />
          <div class="sales-item-wrapper">
            <div class="sales-item" v-for="item in familySaleRate.labels" :key="item">
              <div class="label-color" :style="{ backgroundColor: labelColors[item] }" />
              <div class="sales-item-text">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="products-info">
          <div class="product-label">Son 12 ayda en fazla sattığın ürün</div>
          <div class="product-name">
            {{ getBestSellerOnLast12Month }}
          </div>
          <div class="divider" />
          <div class="product-label">Son 12 ayda en az sattığın ürün</div>
          <div class="product-name">{{ getLeastSellerOnLast12Month }}</div>
        </div>
      </div>
      <div>
        <div class="top-selling-products">
          <div class="top-selling-title">Son 12 Ayda En Çok Sattığın Ürünler</div>
          <div class="product-cards">
            <div class="card" v-for="(item, index) in topSellerProducts" :key="index">
              <div
                class="top-right-circle"
                :style="{ backgroundColor: labelColors[item.productFamilyCode] }"
              />
              <div class="card-content">
                <img
                  style="max-width: 45px; max-height: 75px;"
                  :src="item.image"
                  alt="head-brand"
                />
                <div class="top-product-name">{{ item.productDescription }}</div>
                <h1 class="product-total-divider" />
                <div class="product-total">Toplam Satış</div>
                <div class="product-total-detail">
                  {{ formatDetails(item.totalQuantity) }} Karton
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <BrandBarChart
        style="margin-top: 40px;"
        :chartData="barChartData"
        :chartOptions="barChartOptions"
        :hasBackgroundBarPlugin="true"
      />
      <div class="chart-divider" />
      <div style="display: flex; justify-content: space-between;">
        <div class="chart-date-filter">
          <div
            class="chart-date-item"
            v-for="(item, index) in dateFilterItems"
            @click="toggleDateFilter(item)"
            :key="index"
          >
            <img
              :src="
                item.isActive
                  ? require('@/assets/icons/green-check.svg')
                  : require('@/assets/icons/empty-ellipse.svg')
              "
              alt="icon"
            />
            <div :style="{ color: item.isPrev ? '#ffc107' : '#F16F1B' }" class="chart-date-text">
              {{ item.date, }}
            </div>
          </div>
        </div>
        <div style="display: flex;">
          <BrandMultiSelect
            :options="familyCodes"
            @selectedOptions="selectedFamilyCodes"
            style="margin-right: 20px;"
          />
          <BrandMultiSelect
            :options="subFamilyCodes"
            @selectedOptions="selectedSubFamilyCodes"
            ref="subMultiCodesRef"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BrandPieChart from '@/components/brand/BrandChart/BrandPieChart.vue';
import BrandBarChart from '@/components/brand/BrandChart/BrandBarChart.vue';
import BrandMultiSelect from '@/components/brand/BrandMultiSelect/BrandMultiSelect';
import { BusinessDashboard } from '@/services/Api/index';
import { onBeforeMount, ref, computed, reactive } from 'vue';

const SALES_FILTER_OPTIONS = {
  ALL: 'Hepsi',
  EMPTY: '',
};

export default {
  components: { BrandPieChart, BrandBarChart, BrandMultiSelect },
  setup() {
    const familyCodes = ref([]);
    const subFamilyCodes = ref([]);

    const selectedFilterDate = ref([]);

    const selectedFamilyCode = ref([]);
    const selectedSubFamilyCodeList = ref([]);

    const subMultiCodesRef = ref(null);

    const saleTrackingData = ref(null);

    const dateFilterItems = ref([
      { date: new Date().getFullYear() - 1, isActive: false, isPrev: true },
      { date: new Date().getFullYear(), isActive: true, isPrev: false },
    ]);

    const labelColors = ref({
      ML: '#EB4D4C',
      PL: '#4A51BB',
      CH: '#A258B1',
      LA: '#B4BEC2',
      MU: '#52B596',
      LM: '#FFC107',
    });

    const familySaleRate = ref({
      labels: [],
      datasets: [
        {
          borderColor: 'transparent',
          backgroundColor: [],
          data: [],
        },
      ],
    });

    const familySaleOptions = {
      type: 'pie',
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: 'white',
          formatter: value => {
            if (!value) {
              return '';
            }
            return `${value}%`;
          },
          font: {
            size: 7,
          },
          align: 'end',
        },
      },
    };

    const barChartData = reactive({
      labels: [
        'Ocak',
        'Şubat',
        'Mart',
        'Nisan',
        'Mayıs',
        'Haziran',
        'Temmuz',
        'Ağustos',
        'Eylül',
        'Ekim',
        'Kasım',
        'Aralık',
      ],
      datasets: [
        {
          type: 'line',
          label: '',
          data: [],
          borderColor: '#FFC107',
          pointStyle: 'circle',
          pointRadius: 4,
          pointBackgroundColor: '#FFC107',
          barThickness: 40,
          pointBorderColor: '#FFC107',
          borderWidth: 2,
          datalabels: {
            color: '#FFC107',
            anchor: 'end',
            align: 'top',
            display: 'auto',
            formatter: value => {
              return value === 0 ? '' : Math.round(value);
            },
            clamp: true,
            font: {
              size: 14,
              weight: 500,
            },
          },
        },
        {
          type: 'bar',
          label: '',
          data: [],
          backgroundColor: ['#F16F1B'],
          borderColor: ['#F16F1B'],
          borderRadius: 6,
          borderSkipped: false,
          barThickness: 40,
          datalabels: {
            color: '#F16F1B',
            anchor: 'end',
            align: 'top',
            display: 'auto',
            formatter: value => {
              return value === 0 ? '' : Math.round(value);
            },
            clamp: true,
            font: {
              size: 14,
              weight: 500,
            },
          },
        },
      ],
    });

    const barChartOptions = {
      type: 'bar',
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 12,
            color: '#C2C2C2',
            font: {
              size: 12,
              weight: '400',
            },
          },
        },
        y: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 30,
            color: '#C2C2C2',
            font: {
              size: 12,
              weight: '400',
            },
          },
        },
      },
    };

    const formatDetails = amount => {
      amount = String(amount);

      if (!amount || parseFloat(amount) === 0) {
        return '0 TL';
      }

      let [beforeDecimal, afterDecimal] = amount.split('.');

      if (typeof afterDecimal === 'undefined' || afterDecimal === '') {
        return `${beforeDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
      }

      const formattedBeforeDecimal = beforeDecimal.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `${formattedBeforeDecimal}.${afterDecimal}`;
    };

    const toggleDateFilter = item => {
      item.isActive = !item.isActive;
      dateFilterItems.value = [...dateFilterItems.value];

      if (!dateFilterItems.value.some(item => item.isActive)) {
        barChartData.datasets[0].data = [];
        barChartData.datasets[1].data = [];
      } else {
        updateBarChartData();
      }
      getSaleTracking();
    };

    const updateBarChartData = () => {
      const currentIsActive = dateFilterItems.value.find(item => !item.isPrev && item.isActive);
      const prevIsActive = dateFilterItems.value.find(item => item.isPrev && item.isActive);

      if (currentIsActive && prevIsActive) {
        setYearlySellings(saleTrackingData.value.yearlySellings?.curYearLogs, 1);
        setYearlySellings(saleTrackingData.value.yearlySellings?.prevYearLogs, 0);
        barChartData.datasets[0].datalabels = { display: false };
        barChartData.datasets[1].datalabels = { display: false };
      } else if (currentIsActive) {
        barChartData.datasets[0].data = [];
        setYearlySellings(saleTrackingData.value.yearlySellings?.curYearLogs, 1);
        barChartData.datasets[1].datalabels = getLabelsForBarChart();
        barChartData.datasets[0].datalabels = { display: false };
      } else if (prevIsActive) {
        barChartData.datasets[1].data = [];
        setYearlySellings(saleTrackingData.value.yearlySellings?.prevYearLogs, 0);
        barChartData.datasets[0].datalabels = getLabelsForLineChart();
        barChartData.datasets[1].datalabels = { display: false };
      } else {
        barChartData.datasets[0].data = [];
        barChartData.datasets[1].data = [];
      }
    };

    const updateFamilySaleRateLabels = data => {
      familySaleRate.value.labels = data.familySaleRate?.familyPercentages?.map(m => m.familyCode);
    };

    const convertPercentage = percentage => {
      return Math.round(percentage);
    };

    const updateFamilySaleRateData = data => {
      familySaleRate.value.datasets[0].data = data.familySaleRate?.familyPercentages?.map(item =>
        convertPercentage(item.percentage),
      );

      familySaleRate.value.datasets[0].backgroundColor = data.familySaleRate?.familyPercentages?.map(
        item => labelColors.value[item.familyCode],
      );
    };

    const formatMonthDay = date => {
      return date < 10 ? '0' + date : date?.toString();
    };

    const getSaleTracking = () => {
      let startDate, endDate;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();

      const prevYear = currentDate.getFullYear() - 1;

      const prevYearItem = dateFilterItems.value.find(item => item.isPrev && item.isActive);
      const currentYearItem = dateFilterItems.value.find(item => !item.isPrev && item.isActive);

      if (prevYearItem && !currentYearItem) {
        startDate = `${prevYearItem.date}-01-01`;
        endDate = `${prevYearItem.date}-12-31`;
      } else if (!prevYearItem && currentYearItem) {
        startDate = `${currentYear}-01-01`;
        endDate = `${currentYear}-${formatMonthDay(currentMonth)}-${formatMonthDay(currentDay)}`;
      } else if (prevYearItem && currentYearItem) {
        startDate = `${prevYear}-01-01`;
        endDate = `${currentYear}-${formatMonthDay(currentMonth)}-${formatMonthDay(currentDay)}`;
      }

      const options = {
        productFamilyCode:
          selectedFamilyCode.value?.value === 'Hepsi' ? '' : selectedFamilyCode.value?.value ?? '',
        productSubFamilyCode:
          selectedSubFamilyCodeList.value?.value === 'Hepsi'
            ? ''
            : selectedSubFamilyCodeList.value?.value ?? '',
        startDate: startDate ?? '',
        endDate: endDate ?? '',
      };

      BusinessDashboard.getSaleTracking(options).then(res => {
        if (!res.data.Data) return;
        saleTrackingData.value = res.data.Data;
        updateFamilySaleRateLabels(res.data.Data);
        updateFamilySaleRateData(res.data.Data);
        setYearlySellings(res.data.Data.yearlySellings.curYearLogs, 1);
        setYearlySellings(res.data.Data.yearlySellings.prevYearLogs, 0);
        familyCodes.value = extractFamilyCodes(res.data.Data.filterOptions);
        updateBarChartData();
      });

      barChartData.datasets[0].label = dateFilterItems.value[0].date;
      barChartData.datasets[1].label = dateFilterItems.value[1].date;
    };

    const extractFamilyCodes = data => {
      if (!data || !Array.isArray(data))
        return [{ value: SALES_FILTER_OPTIONS.ALL, label: SALES_FILTER_OPTIONS.ALL }];

      const uniqueFamilyCodes = [...new Set(data.map(item => item.familyCode))];

      return [
        { value: SALES_FILTER_OPTIONS.ALL, label: SALES_FILTER_OPTIONS.ALL },
        ...uniqueFamilyCodes.sort().map(code => ({
          value: code,
          label: code,
        })),
      ];
    };

    const getBestSellerOnLast12Month = computed(() => {
      return saleTrackingData.value?.familySaleRate?.bestSellerOnLast12Month ?? '';
    });

    const getLeastSellerOnLast12Month = computed(() => {
      return saleTrackingData.value?.familySaleRate?.leastSellerOnLast12Month ?? '';
    });

    const topSellerProducts = computed(() => {
      return saleTrackingData.value?.topSellerProducts || [];
    });

    const setYearlySellings = (data, dataSetIndex) => {
      const yearlyData = Array.from({ length: barChartData.labels.length }, () => 0);

      data?.forEach(item => {
        const monthIndex = parseInt(item.date.slice(-2)) - 1;
        if (monthIndex >= 0 && monthIndex < yearlyData.length) {
          yearlyData[monthIndex] = item.totalQuantity;
        }
      });

      const maxValue = yearlyData.reduce((max, current) => {
        return current > max ? current : max;
      }, yearlyData[0]);

      barChartData.datasets[dataSetIndex].data = [...yearlyData, maxValue + 50];
    };

    const selectedFamilyCodes = codes => {
      if (Array.isArray(codes) && codes.length > 0) {
        const selectedCode = codes[0];

        if (!selectedCode.value || selectedCode.value === SALES_FILTER_OPTIONS.ALL) {
          selectedFamilyCode.value = {
            value: SALES_FILTER_OPTIONS.EMPTY,
            label: SALES_FILTER_OPTIONS.ALL,
          };
          subMultiCodesRef.value.selectedOptions = null;
          subFamilyCodes.value = [
            { value: SALES_FILTER_OPTIONS.ALL, label: SALES_FILTER_OPTIONS.ALL },
          ];
        } else {
          selectedFamilyCode.value = selectedCode;
          subMultiCodesRef.value.selectedOptions = null;
          subFamilyCodes.value = [
            { value: SALES_FILTER_OPTIONS.ALL, label: SALES_FILTER_OPTIONS.ALL },
            ...saleTrackingData.value.filterOptions
              .filter(filterOption => selectedCode.value === filterOption.familyCode)
              ?.map(filterOption => ({
                value: filterOption.subFamilyCode,
                label: filterOption.productDescription,
              })),
          ];
        }
      } else {
        selectedFamilyCode.value = {
          value: SALES_FILTER_OPTIONS.EMPTY,
          label: SALES_FILTER_OPTIONS.ALL,
        };
        subMultiCodesRef.value.selectedOptions = null;
        subFamilyCodes.value = [
          { value: SALES_FILTER_OPTIONS.ALL, label: SALES_FILTER_OPTIONS.ALL },
        ];
      }

      selectedSubFamilyCodeList.value = { value: '', label: '' };
      getSaleTracking();
    };

    const selectedSubFamilyCodes = codes => {
      if (Array.isArray(codes) && codes.length > 0) {
        const selectedCode = codes[0];

        if (!selectedCode.value || selectedCode.value === SALES_FILTER_OPTIONS.ALL) {
          selectedSubFamilyCodeList.value = {
            value: SALES_FILTER_OPTIONS.EMPTY,
            label: SALES_FILTER_OPTIONS.ALL,
          };
        } else {
          selectedSubFamilyCodeList.value = selectedCode;
        }
      } else {
        selectedSubFamilyCodeList.value = {
          value: SALES_FILTER_OPTIONS.EMPTY,
          label: SALES_FILTER_OPTIONS.ALL,
        };
      }

      getSaleTracking();
    };

    const getLabelsForBarChart = () => {
      return {
        color: '#F16F1B',
        anchor: 'end',
        align: 'top',
        display: 'auto',
        formatter: value => {
          return value === 0 ? '' : Math.round(value);
        },
        clamp: true,
        font: {
          size: 14,
          weight: 500,
        },
      };
    };

    const getLabelsForLineChart = () => {
      return {
        color: '#FFC107',
        anchor: 'end',
        align: 'top',
        display: 'auto',
        formatter: value => {
          return value === 0 ? '' : Math.round(value);
        },
        clamp: true,
        font: {
          size: 14,
          weight: 500,
        },
      };
    };

    onBeforeMount(() => {
      getSaleTracking();
    });

    return {
      labelColors,
      familyCodes,
      formatDetails,
      subFamilyCodes,
      familySaleRate,
      familySaleOptions,
      barChartData,
      subMultiCodesRef,
      barChartOptions,
      dateFilterItems,
      saleTrackingData,
      topSellerProducts,
      toggleDateFilter,
      setYearlySellings,
      selectedFilterDate,
      extractFamilyCodes,
      selectedFamilyCodes,
      selectedFamilyCode,
      getLabelsForLineChart,
      getLabelsForBarChart,
      selectedSubFamilyCodes,
      selectedSubFamilyCodeList,
      getBestSellerOnLast12Month,
      getLeastSellerOnLast12Month,
    };
  },
};
</script>

<style scoped lang="scss">
.sales-tracking {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sales-tracking-top {
  display: flex;
  justify-content: center;
}
.family-sales {
  width: 335px;
  height: 385px;
  border-radius: 10px;
  background: #f8f9f9;
  border: solid 1px #ececec;
}
.title {
  font-weight: 700;
  margin: 15px 0 0 20px;
}

.top-selling-title {
  margin-left: 20px;
  font-weight: 700;
}

.sales-pie-wrapper {
  display: flex;
  position: relative;
  padding-bottom: 10px;
}

.sales-pie-wrapper::after {
  content: '';
  position: absolute;
  bottom: 00;
  width: calc(100% - 40px);
  height: 1px;
  background-color: #e1e1e1;
  opacity: 0.8;
  left: 20px;
}

.pie-chart {
  width: 75%;
  height: 140px;
  margin-left: 20px;
  margin-top: 15px;
}

.sales-item-wrapper {
  width: 25%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;
  margin-top: 55px;
}

.sales-item {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  .label-color {
    border-radius: 8px;
    width: 8px;
    height: 8px;
    margin-right: 8px;
  }

  .sales-item-text {
    text-transform: uppercase;
    color: #848a9c;
    font-size: 10px;
    font-weight: 400;
  }
}

.products-info {
  margin: 10px 0 0 20px;
}

.products-info::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
  height: 1px;
  background-color: #e1e1e1;
  opacity: 0.8;
  left: 20px;
}

.product-label {
  color: #79838e;
  font-size: 14px;
  font-weight: 500;
}

.product-name {
  font-size: 14px;
  font-weight: 700;
  margin: 12px 0;
}

.divider {
  height: 1px;
  background-color: #e1e1e1;
  opacity: 0.8;
  margin: 15px 0;
  width: 100%;
  left: 20px;
}

.top-selling-products {
  width: 485px;
  height: 385px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #ededed;
  background: #f8f9f9;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-cards {
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;

  .card {
    width: 140px;
    height: 300px;
    border-radius: 5px;
    border: 1px solid #ededed;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .top-product-name {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    margin: 18px 0 6px 0;
  }

  .product-total {
    color: #79838e;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 6px;
  }

  .product-total-detail {
    font-size: 12px;
    font-weight: 600;
  }

  .product-total-divider {
    height: 1px;
    background-color: #e6e6e6;
    width: 70%;
    margin: 12px 0;
  }

  .top-right-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
}
.chart-divider {
  height: 1px;
  background-color: #ededed;
  width: 870px;
}

.chart-date-filter {
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
}

.chart-date-filter + div {
  display: flex;
  justify-content: flex-end;
}

.chart-date-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}
.chart-date-text {
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
}
</style>
