const BASE_URLS = {
  dev: 'https://content.pmaktiftr-dev.aws.pmicloud.biz',
  qa: 'https://content.pmaktiftr-qa.aws.pmicloud.biz',
  prod: 'https://content.pmaktif.com',
};

const ASSET_PATH = '/assets/images/partnericon';

export const getPartnerImage = partnerCode => {
  if (!partnerCode) {
    throw new Error('Partner code is required');
  }

  const env = process.env.VUE_APP_API_BASE_URL;
  let environment = 'prod';

  if (env.includes('dev')) {
    environment = 'dev';
  } else if (env.includes('qa')) {
    environment = 'qa';
  }

  const baseUrl = BASE_URLS[environment];
  return `${baseUrl}${ASSET_PATH}/${partnerCode}_logo.png`;
};
