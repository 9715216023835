import { HttpService } from '@/services/HttpService';

class VirtualVisit {
  subdomain = '/virtualvisit';
  /**
   * Get virtual visit
   * @returns {Promise}
   */
  getVirtualVisit() {
    return HttpService.get(`${this.subdomain}`);
  }
  /**
   * Get virtual visit validate
   * @returns {Promise}
   */
  virtualVisitValidate(params) {
    return HttpService.post(`${this.subdomain}/validate`, params);
  }
  /**
   * Get virtual visit savelog
   * @returns {Promise}
   */
  virtualVisitSaveLog(params) {
    return HttpService.post(`${this.subdomain}/savelog`, params);
  }
  /**
   * Get virtual exception
   * @returns {Promise}
   */
  VirtualVisitException(params) {
    return HttpService.post(`${this.subdomain}/exception`, params);
  }
  /**
   * Get virtual visit savescenes
   * @returns {Promise}
   */
  virtualVisitSaveScenes(params) {
    return HttpService.post(`${this.subdomain}/savescenes`, params);
  }
  /**
   * Get virtual visit savescenes
   * @returns {Promise}
   */
  getVirtualVisitStatus(params) {
    return HttpService.get(`${this.subdomain}/status`, params);
  }
}

export default new VirtualVisit();
