import { APP_MUTATION_TYPES } from '@/store/app/mutations';

export const AppActions = {
  setCookieConsentVersion: ({ commit }, version) =>
    commit(APP_MUTATION_TYPES.SET_COOKIE_CONSENT_VERSION, version),
  setOverlayVisibility: ({ commit }, status) =>
    commit(APP_MUTATION_TYPES.SET_OVERLAY_VISIBILITY, status),
  setOverlayFunction: ({ commit }, status) =>
    commit(APP_MUTATION_TYPES.SET_OVERLAY_FUNCTION, status),
  setAppbarConfig: ({ commit }, config) => commit(APP_MUTATION_TYPES.SET_APPBAR_CONFIG, config),
  setMenuStatus: ({ commit }, status) => commit(APP_MUTATION_TYPES.SET_MENU_STATUS, status),
  setBottomBarStatus: ({ commit }, status) =>
    commit(APP_MUTATION_TYPES.SET_BOTTOMBAR_STATUS, status),
  setModalConfig: ({ commit }, config) => commit(APP_MUTATION_TYPES.SET_MODAL_CONFIG, config),
  setMenuItems: ({ commit }, items) => commit(APP_MUTATION_TYPES.SET_MENU_ITEMS, items),
  setCurrentMenuItems: ({ commit }, currentItems) =>
    commit(APP_MUTATION_TYPES.SET_CURRENT_MENU_ITEMS, currentItems),
  setScrollStatus: ({ commit }, status) => commit(APP_MUTATION_TYPES.SET_SCROLL_STATUS, status),
  setFunPageModalStatus: ({ commit }, status) =>
    commit(APP_MUTATION_TYPES.SET_MODAL_ON_FUNPAGE, status),
  setSearchedText: ({ commit }, status) => commit(APP_MUTATION_TYPES.SET_SEARCHED_TEXT, status),
  setCustomerDetail: ({ commit }, customerDetail) =>
    commit(APP_MUTATION_TYPES.SET_CUSTOMER_DETAIL, customerDetail),
  setCustomerPoints: ({ commit }, customerPoints) =>
    commit(APP_MUTATION_TYPES.SET_CUSTOMER_POINTS, customerPoints),
  setCookieSettings: ({ commit }, cookieSettings) =>
    commit(APP_MUTATION_TYPES.SET_COOKIE_SETTINGS, cookieSettings),
  setVirtualVisit: ({ commit }, url) => commit(APP_MUTATION_TYPES.SET_VIRTUAL_VISIT_URL, url),
  setAddedToHomeScreenStatus: ({ commit }, addedToHomeScreenStatus) =>
    commit(APP_MUTATION_TYPES.SET_ADDED_TO_HOME_SCREEN_STATUS, addedToHomeScreenStatus),
  setDeviceType: ({ commit }, type) => commit(APP_MUTATION_TYPES.SET_DEVICE_TYPE, type),
  setErrorModalConfig: ({ commit }, config) =>
    commit(APP_MUTATION_TYPES.SET_ERROR_MODAL_CONFIG, config),
  setDoWinCardRefresh: ({ commit }, refresh) =>
    commit(APP_MUTATION_TYPES.SET_DOWIN_CARD_REFRESH, refresh),
  setRefreshMarathonBanner: ({ commit }, refresh) =>
    commit(APP_MUTATION_TYPES.SET_MARATHON_CARD_REFRESH, refresh),
  setStatusModalConfig: ({ commit }, config) =>
    commit(APP_MUTATION_TYPES.SET_STATUS_MODAL_CONFIG, config),
  setRacks: ({ commit }, racks) => commit(APP_MUTATION_TYPES.SET_RACKS, racks),
  setQrResponse: ({ commit }, response) => commit(APP_MUTATION_TYPES.SET_QR_RESPONSE, response),
  setLocation: ({ commit }, location) => commit(APP_MUTATION_TYPES.SET_LOCATION, location),
  setCloseClick: ({ commit }, val) => commit(APP_MUTATION_TYPES.SET_CLOSE_CLICK, val),
  setOtpCategoryStatuses: ({ commit }, data) =>
    commit(APP_MUTATION_TYPES.SET_OTP_CATEGORY_STATUSES, data),
  setLastPosition: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_LAST_POSITION, data),
  setContentTypes: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_CONTENT_TYPES, data),
  setCategories: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_CATEGORIES, data),
  setActiveDowinCount: ({ commit }, data) =>
    commit(APP_MUTATION_TYPES.SET_ACTIVE_DOWIN_COUNT, data),
  setQrViewStatus: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_QR_VIEW_STATUS, data),
  setNpsFinished: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_NPS_FINISHED, data),
  setChangedPosToken: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_CHANGED_POS_TOKEN, data),
  setRosettes: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_ROSETTES, data),
  setPopups: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_POPUPS, data),
  setFeedTaskDetail: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_FEED_TASK_DETAIL, data),
  clearFeedTaskDetail({ commit }) {
    commit(APP_MUTATION_TYPES.SET_FEED_TASK_DETAIL);
  },
  setRosetteRewardStatu: ({ commit }, data) =>
    commit(APP_MUTATION_TYPES.SET_ROSETTE_REWARD_STATU, data),
  setCurrentMarathon: ({ commit }, data) => commit(APP_MUTATION_TYPES.SET_CURRENT_MARATHON, data),
  setNotificationCount: ({ commit }, { count, visibility }) => {
    commit(APP_MUTATION_TYPES.SET_NOTIFICATION_COUNT, count);
    commit(APP_MUTATION_TYPES.SET_NOTIFICATIONS_VISIBILITY, visibility);
  },
};
