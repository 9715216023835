//check input if its a date object
const isDate = input => {
  if (Object.prototype.toString.call(input) === '[object Date]') {
    return true;
  } else {
    return false;
  }
};

//get difference data between two dates
const dateDifference = (isoDate2, isoDate1) => {
  let dt1 = !isDate(isoDate1) ? convertISODate(isoDate1) : isoDate1;
  let dt2 = !isDate(isoDate2) ? convertISODate(isoDate2) : isoDate2;

  let time = dt2.getTime() - dt1.getTime();
  let hours = Math.round(time / 3600000);
  let days = Math.round(time / (3600000 * 24));

  return { time, hours, days };
};
//Convert ISO Date string to Date object
const convertISODate = date => {
  let a, newDate;
  if (date) {
    a = date.split(/[^0-9]/);
    if (a && a.length > 5) {
      newDate = new Date(a[0], a[1] - 1, a[2], a[3], a[4], a[5]);
    } else if (a && a.length > 2) {
      newDate = new Date(a[0], a[1] - 1, a[2]);
    } else {
      newDate = new Date(date);
    }
  }
  return newDate;
};
//format ISO Date string to locale date string
const parseISODate = (startDate, endDate) => {
  let newDate = startDate ? convertISODate(startDate) : null;
  if (newDate) {
    if (!endDate) {
      return newDate.toLocaleDateString('tr');
    } else {
      let newEndDate = convertISODate(endDate);
      let locale = {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      };
      return `${newDate.toLocaleDateString('tr', locale)} / ${newEndDate.toLocaleDateString(
        'tr',
        locale,
      )}`;
    }
  }
  return '';
};

//format ISO Date string to locale date  and time string
const toLocaleDateTimeString = (date, options) => {
  let dateStr = '';
  let newDate = convertISODate(date);
  if (newDate) {
    dateStr = newDate.toLocaleString('tr');
    if (options) {
      if (options.format) {
        dateStr = newDate.toLocaleDateString('tr', options.format);
      }
    }
  }
  return dateStr;
};

const toISODateString = date => {
  let arrDate = date.split('.');
  let newDate = new Date(arrDate[2], Number(arrDate[1]) - 1, Number(arrDate[0]) + 1);
  return newDate.toISOString();
};

const getNameOfMonth = date => {
  const months = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ];

  const d = date ? date : new Date();
  return months[d.getMonth()];
};

const padZero = number => {
  return number < 10 ? `0${number}` : number;
};

const formatDate = dateTime => {
  const date = new Date(dateTime);
  return `${padZero(date.getDate())}/${padZero(
    date.getMonth() + 1,
  )}/${date.getFullYear()} · ${padZero(date.getHours())}.${padZero(date.getMinutes())}`;
};

export default {
  filters: {
    parseISODate(startDate, endDate) {
      return parseISODate(startDate, endDate);
    },
    toLocaleDateTimeString(date, options) {
      return toLocaleDateTimeString(date, options);
    },
    toISODateString(date) {
      return toISODateString(date);
    },
    convertISODate(date) {
      return convertISODate(date);
    },
    dateDifference(date1, date2) {
      return dateDifference(date1, date2);
    },
  },
  methods: {
    parseISODate(startDate, endDate) {
      return parseISODate(startDate, endDate);
    },
    toLocaleDateTimeString(date, options) {
      return toLocaleDateTimeString(date, options);
    },
    toISODateString(date) {
      return toISODateString(date);
    },
    convertISODate(date) {
      return convertISODate(date);
    },
    dateDifference(date1, date2) {
      return dateDifference(date1, date2);
    },
    getNameOfMonth(date) {
      return getNameOfMonth(date);
    },
    padZero(number) {
      return padZero(number);
    },
    formatDate(dateTime) {
      return formatDate(dateTime);
    },
  },
};
