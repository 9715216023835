<template>
  <div class="page-container">
    <div class="content-row gray-bg">
      <div class="content-layout-fixer">
        <div class="title-bar">
          <BrandLogo :width="170" :height="170" :image="partner.image" />
          <div class="title-bar-content">
            <ul class="title-bar-icons">
              <li>
                <a class="title-bar-icon" id="title_bar_icon" @click.prevent="createPdf(true)">
                  <VueIcon
                    :iconName="icon.print.name"
                    :width="icon.print.width"
                    :height="icon.print.height"
                    :iconColor="'#79838e'"
                  />
                  <VueText
                    class="title-bar-icon-text"
                    :color="'grey-30'"
                    sizeLevel="4"
                    weightLevel="3"
                    >Yazdır</VueText
                  >
                </a>
              </li>
              <li>
                <a class="title-bar-icon" id="title_bar_icon" @click="createPdf(false)">
                  <VueIcon
                    :iconName="icon.share.name"
                    :width="icon.share.width"
                    :height="icon.share.height"
                    :iconColor="'#79838e'"
                  />
                  <VueText
                    class="title-bar-icon-text"
                    :color="'grey-30'"
                    sizeLevel="4"
                    weightLevel="3"
                    >Kaydet</VueText
                  >
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <PartnerPriceList
      @getPriceList="getPriceList"
      @getPriceListInfoText="getPriceListInfoText"
      @createDate="getCreateDate"
      :partnerId="horizonPartnerId"
    />
  </div>
</template>

<script>
import { ref, computed, reactive } from 'vue';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import '@/assets/ulker/Roboto-Regular-normal.js';
import PartnerPriceList from '../PartnerPriceList.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES } from '@/constants/component.constants';
import dateUtils from '@/mixins/dateUtils.js';
import { jsPDF } from 'jspdf';
require('jspdf-autotable');
import { getPartnerImage } from '@/utils/partnerImageUtils.js';

export default {
  name: 'List',
  mixins: [dateUtils],
  components: {
    BrandLogo,
    VueText,
    PartnerPriceList,
    VueIcon,
  },
  setup() {
    const horizonPartnerId = ref('5');
    const priceListInfoText = ref('');
    const createDate = ref('');
    const priceList = ref([]);
    const partner = reactive({
      code: 'HORIZON',
      image: getPartnerImage('horizon'),
    });

    const icon = computed(() => {
      return {
        share: ICON_VARIABLES.share,
        print: ICON_VARIABLES.print,
      };
    });

    const getCreateDate = date => {
      createDate.value = date;
    };
    const createPdf = isPrint => {
      const doc = new jsPDF();

      const tableColumns = [
        'Tavsiye Edilen Satış Fiyatı',
        'Alış Fiyatı',
        'Ön Sipariş İçeriği',
        'Kategori',
        'Marka',
        'Ürün Kodu',
      ];

      const tableData = priceList.value.map(item => Object.values(item));
      tableData.shift();
      tableData.forEach(i => i.shift());

      doc.setFontSize(11);
      doc.setFont('Roboto-Regular');
      doc.setTextColor(100);

      let text1 = doc.splitTextToSize(priceListInfoText.value, 300, {});
      doc.text(text1, 14, 10);

      let text2 = doc.splitTextToSize(`Son Güncelleme Tarihi: ${createDate.value}`, 300, {});
      doc.text(text2, 14, 15);

      doc.autoTable({
        styles: {
          minCellHeight: 14,
          valign: 'middle',
          font: 'Roboto-Regular',
          fontStyle: 'normal',
        },
        columnStyles: {
          1: { halign: 'center' },
          2: { minCellWidth: 30, valign: 'middle', halign: 'center' },
        },
        head: [tableColumns],
        body: tableData,
        startY: 20,
        showHead: 'firstPage',
      });

      if (isPrint) {
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
      } else {
        doc.save('horizon-guncel-fiyat-ve-urun-listesi.pdf');
      }
    };

    const getPriceList = data => {
      priceList.value = data;
    };

    const getPriceListInfoText = data => {
      priceListInfoText.value = data;
    };

    return {
      icon,
      partner,
      createPdf,
      priceList,
      createDate,
      getCreateDate,
      getPriceList,
      horizonPartnerId,
      getPriceListInfoText,
      priceListInfoText,
    };
  },
};
</script>

<style lang="scss" scoped>
.content-layout-fixer {
  padding-left: 20px;
}
@mixin flex-center {
  display: flex;
  align-items: center;
}
.page-container {
  .content-row {
    height: unset;
  }
  .gray-bg {
    background: #f8f9f9;
    margin-bottom: 30px;
    padding: 30px 0;
  }
  /deep/ .crumbs-holder {
    padding-left: 0;
  }
  .price-list-toolbar {
    padding: palette-space-level(20);
  }
  .actions {
    a {
      width: inherit;
    }
  }
  .title-bar {
    @include flex-center;
    margin-bottom: palette-space-level(5);
    .title-bar-content {
      @include flex-center;
      position: relative;
      width: 100%;
      justify-content: space-between;
      padding-left: palette-space-level(10);
      padding-right: palette-space-level(10);
      .title-text {
        width: 400px;
      }
    }
    ul.title-bar-icons {
      position: absolute;
      right: 0;
      list-style-type: none;
      padding: 0;
      margin: 0 15px 0 0;
      li {
        float: left;
        margin-left: palette-space-level(30);

        cursor: pointer;
        a {
          display: flex;
          text-decoration: none;
        }
        .title-bar-icon {
          svg {
            position: relative;
            top: 2px;
            width: 18px;
            height: 18px;
            margin-right: palette-space-level(10);
          }
        }
      }
    }
  }
}
</style>
