<template>
  <div class="layout-default">
    <BrandHeader @refresh-home-screen="eventRefreshHomeScreen" />
    <DynamicPopup ref="dynamicPopup" v-show="!this.isLoadingPopups && !this.currentPopup.popupId" />
    <div class="body-container">
      <div class="layout-main-holder">
        <NpsSurvey v-if="getVisibleNps" :currentPopup="currentPopup" @closeModal="closeNpsModal" />
        <div
          :class="[{ 'qrpage-style': qrpageStyle }, 'breadcrumb-container']"
          v-if="breadcrumbList"
        >
          <ul>
            <li
              v-for="(breadcrumb, index) in breadcrumbList"
              :key="index"
              @click="routeTo(index)"
              :class="{ linked: !!breadcrumb.link }"
            >
              <span>{{ breadcrumb.name }}</span>
            </li>
          </ul>
        </div>
        <div
          class="content-holder"
          :style="{ backgroundColor: bgColor }"
          :class="contentHolderClass"
        >
          <div :key="refreshHomeScreen">
            <slot />
          </div>
        </div>
        <FooterBig></FooterBig>
      </div>
      <BrandSideBar :currentPopup="currentPopup" />
      <BrandCookieModal
        :showModal="cookieModal.show"
        @onModalClose="closeCookiesModal()"
        @acceptedCookies="acceptCookies()"
        @click="closeCookiesModal()"
      >
      </BrandCookieModal>
    </div>
    <BrandModal></BrandModal>
    <transition name="slide">
      <VueMenu v-if="currentMenuItems" v-show="getMenuStatus" @changedPos="changedPos"></VueMenu>
    </transition>
    <BrandInfoPopup :isChangePos="changedPosPopup" :show.sync="changedPosPopup">
      <SelectPos :isChangePos="changedPosPopup" @closePopUp="closePopUp" />
    </BrandInfoPopup>
  </div>
</template>

<script>
import BrandHeader from '@/components/brand/BrandHeader/BrandHeader';
import BrandSideBar from '@/components/brand/BrandSideBar/BrandSideBar';
import FooterBig from '@/components/brand/BrandFooter/FooterBig/FooterBig';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import VueMenu from '@/components/VueMenu/VueMenu.vue';
import StorageProps from '@/mixins/storageProps.js';
import { mapGetters } from 'vuex';
import StorageHelper from '@/utils/storageHelper';
import { Common, User, Notification } from '@/services/Api/index';
import BrandCookieModal from '@/components/brand/Modals/BrandModal/BrandCookieModal.vue';
import { getScreenCodes, arrTranspiler } from '@/utils/brandUtils.js';
import BrandInfoPopup from '@/components/brand/Modals/BrandModal/BrandInfoPopup.vue';
import SelectPos from '@/views/pages/public/authentication/login/SelectPos';
import NpsSurvey from '@/views/pages/secure/NpsSurvey.vue';
import gtmUtils from '@/mixins/gtmUtils.js';
import DynamicPopup from '../../../src/views/pages/secure/dynamicPopups/DynamicPopup.vue';

export default {
  name: 'LayoutSecure',
  mixins: [StorageProps, gtmUtils],
  components: {
    BrandHeader,
    BrandSideBar,
    FooterBig,
    VueMenu,
    BrandModal,
    BrandCookieModal,
    BrandInfoPopup,
    SelectPos,
    NpsSurvey,
    DynamicPopup,
  },
  data() {
    return {
      cookieModal: { show: false },
      refreshHomeScreen: 0,
      breadcrumbList: [],
      changedPosPopup: false,
      currentPopup: {},
      isLoadingPopups: true,
    };
  },

  created() {
    this.initApp();
  },

  mounted() {
    this.cookieModal.show = !this.isConsent;
    this.updateBreadcrumbList();
    this.getPopupsInfo();

    if (this.isAuthenticated) {
      this.fetchNotificationCount();
    }
  },

  computed: {
    ...mapGetters('app', [
      'getMenuStatus',
      'getBottomBarStatus',
      'getScrollStatus',
      'getQrViewStatus',
    ]),
    ...mapGetters('auth', ['getUserModel', 'isAuthenticated', 'getAfterLogin']),
    isConsent() {
      return this.getCookieSettings ? this.getCookieSettings.isConsent : 'NotSet';
    },
    bgColor() {
      return this.getQrViewStatus ? '#959fae' : '#fff';
    },
    contentHolderClass() {
      return this.$route.name === 'Otp' || this.$route.name === 'Learn' ? 'image-bg' : '';
    },
    qrpageStyle() {
      return this.getQrViewStatus;
    },
    getVisibleNps() {
      return this.currentPopup.popupId == 6 && this.$route.fullPath == '/secure/feed';
    },
  },
  watch: {
    //** Watch overlay state for toggle body scroll */
    getMenuStatus: {
      immediate: true,
      handler(val) {
        if (val) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'unset';
      },
    },
    isConsent: {
      handler(last) {
        if (last !== 'NotSet') {
          this.cookieModal.show = !last;
        }
      },
    },
    $route() {
      this.updateBreadcrumbList();
      if (this.$route.fullPath == '/secure/feed') {
        this.getPopupsInfo();
      } else {
        if (!this.isLoadingPopups && !this.currentPopup.popupId) {
          this.$refs.dynamicPopup.$emit('show');
        }
      }
    },
    isAuthenticated(newValue) {
      if (newValue) {
        this.fetchNotificationCount();
      }
    },
  },
  methods: {
    eventRefreshHomeScreen() {
      this.refreshHomeScreen += 1;
    },
    changedPos(value) {
      this.changedPosPopup = value;
    },
    closePopUp(staffValidation) {
      this.changedPosPopup = false;
      if (!staffValidation) return;
      window.location.reload();
    },

    async fetchNotificationCount() {
      try {
        const res = await Notification.getNotificationCount();
        const count = res?.data?.Data?.activeNotificationCount ?? 0;
        const visibility = res?.data?.Data?.notificationsVisibility ?? false;

        this.$store.dispatch('app/setNotificationCount', {
          count,
          visibility,
        });
      } catch (error) {
        console.error('Error fetching notification count:', error);
      }
    },

    initApp() {
      const cachedMenuData = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_MENU_ITEMS,
      }).get();
      const cachedChatbotModel = new StorageHelper({
        id: process.env.VUE_APP_STORAGE_CHATBOT_MODEL,
      }).get();

      if (!cachedMenuData || !cachedMenuData.timestamp) {
        this.resetMenu();
      } else {
        const currentTime = Date.now();
        const thirtyMinutesInMs = 30 * 60 * 1000;
        const isExpired = currentTime - cachedMenuData.timestamp > thirtyMinutesInMs;

        if (isExpired) {
          this.resetMenu();
        } else {
          this.initMenu(cachedMenuData, false);
        }
      }

      if (!cachedChatbotModel) {
        this.resetChatbot();
      } else {
        this.initChatbot(cachedChatbotModel);
      }
    },

    resetMenu() {
      Common.getMenu().then(res => {
        if (res && res.data && res.data.Data) {
          this.initMenu(res.data.Data, true);
        }
      });
    },

    initMenu(menuData, setStorage) {
      try {
        const userMenu = arrTranspiler(menuData.menuItems);
        const screenCodes = getScreenCodes(menuData.menuItems);
        const flatRoutes = this.getRoutesFlat(this.$router.options.routes);
        const mergedMenuItems = {
          menuItems: this.mergeItems(userMenu, flatRoutes),
          partners: menuData.businessPartners,
          isChangePosVisible: menuData.isChangePosVisible,
        };
        this.$store.dispatch('app/setCurrentMenuItems', mergedMenuItems);
        this.$store.dispatch('app/setMenuItems', mergedMenuItems);
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_SCREEN_CODES }).set(screenCodes);

        if (setStorage) {
          const menuDataWithTimestamp = { ...menuData, timestamp: Date.now() };
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_MENU_ITEMS }).set(
            menuDataWithTimestamp,
          );
        }
      } catch {
        if (!setStorage) {
          this.resetMenu();
        }
      }
    },

    initChatbot(chatbot, setStorage) {
      try {
        if (setStorage) {
          new StorageHelper({ id: process.env.VUE_APP_STORAGE_CHATBOT_MODEL }).set(chatbot);
        }

        const user = new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_KEY }).get();

        User.getChatbotData().then(res => {
          if (res && res.data && res.data.Data) {
            window._sorun_set_variable = JSON.stringify(res.data.Data.chatBotData);
            window._sorun_set_mobile_in_iframe = 'true';
            window._sorun_set_name = chatbot.firstName;
            window._sorun_set_surname = chatbot.lastName;
            window._sorun_set_companyID = chatbot.companyId;
            window._sorun_set_lang = 'tr';
            window._sorun_set_token = chatbot.chatToken;
            window._sorun_set_mobileIframeOnloadDisabled = 'true';
            window.mbHideIcon = true;
            window.MB_CLIENT = user.id;
            window.MB_TOKEN = chatbot.chatToken;
            window.MB_VARIABLE = res.data.Data.chatBotData;
            if (chatbot.poolFilter !== 'undefined') {
              window._sorun_set_poolFilter = chatbot.poolFilter;
            }
            if (chatbot.autoOpen) {
              window._sorun_set_auto_open_delay = 1;
            }
            new StorageHelper({ id: 'ChatbotScript' }).set(chatbot.scriptUrl);
            this.loadScript(chatbot.scriptUrl);
          }
        });
      } catch {
        if (!setStorage) {
          this.resetChatbot();
        }
      }
    },
    resetChatbot() {
      User.getChatbotModel().then(res => {
        if (res && res.data && res.data.Data) {
          this.initChatbot(res.data.Data.chatbot, true);
        }
      });
    },
    closeCookiesModal() {
      this.cookieModal.show = false;
    },
    acceptCookies() {
      User.setUserCookieSettings({
        functionalCookie: true,
        trackingCookie: true,
        performanceCookie: true,
      }).then(res => {
        this.$store.dispatch('app/setCookieSettings', res.data.Data.cookieSettings);
        new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_COOKIE_SETTINGS }).set(
          res.data.Data.cookieSettings,
        );
      });
      this.cookieModal.show = false;
    },
    async loadScript(src) {
      return new Promise(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src = src;
        document.head.append(script);
      });
    },
    getRoutesFlat(routes, output = {}, parentPath = '') {
      routes.forEach(route => {
        const { name, path, children, meta } = route;
        const absolutePath = parentPath.length ? parentPath + '/' + path : path;
        if (meta.screenCode) {
          output[meta.screenCode] = {
            name: name,
            path: path,
            absolutePath: absolutePath,
            meta: meta,
            children: children && children.length ? children : null,
          };
        } else {
          return;
        }

        if (route.children && route.children.length) {
          this.getRoutesFlat(route.children, output, absolutePath);
        }
      });
      return { ...output };
    },
    mergeItems(serviceData, routeData) {
      Object.keys(serviceData).forEach(key => {
        const sc = serviceData[key].screenCode;
        Object.keys(routeData).forEach(subKey => {
          if (routeData[subKey].meta.screenCode === sc) {
            serviceData[key]['absolutePath'] = routeData[subKey].absolutePath;
            serviceData[key]['icon'] = routeData[subKey].meta.icon;

            if (serviceData[key].children) {
              serviceData[key] = {
                ...serviceData[key],
                ...this.mergeItems(serviceData[key].children, routeData),
              };
            }
          }
        });
      });
      return serviceData;
    },
    routeTo(idx) {
      if (this.breadcrumbList[idx].link) this.$router.push(this.breadcrumbList[idx].link);
    },
    updateBreadcrumbList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
    async getPopupsInfo() {
      if (this.getUserModel.isImageRecognitionUser) return;

      try {
        const { data } = await Common.getPopups(this.getAfterLogin);
        if (data.Data) {
          this.currentPopup = data.Data;
          this.$store.dispatch('app/setPopups', data.Data);

          if (data.Data.popupId === 3) {
            setTimeout(() => {
              window.scrollTo(0, 500);
            }, 1000);
          }
        }
      } finally {
        this.isLoadingPopups = false;
      }

      this.$store.dispatch('auth/setAfterLogin', false);
    },
    setPopupClickEvent(isCancelled) {
      this.pushDataLayerEvent('popup_click', {
        event: 'popup_click',
        popup_location: 'anasayfa',
        popup_cancelled: isCancelled,
        popup_name: 'NPS',
        popup_platform: 'PmAktif',
      });
    },
    closeNpsModal(isCancelled) {
      this.setPopupClickEvent(isCancelled);
      this.currentPopup = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.body-container {
  display: flex;
  flex-direction: row;
  min-width: $body-width;

  .layout-main-holder {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: auto;

    .content-holder {
      min-height: calc(100vh - 80px);
      padding-bottom: palette-space-level(50);
    }
  }
}

.layout-default {
  position: absolute;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  &.menu-opened {
    height: 100vh;
    overflow: hidden;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
<style lang="scss">
#_sorun_icon {
  display: none !important;
}
.breadcrumb-container {
  width: 100%;
  border-bottom: solid 1px #f2f2f2;
  &.qrpage-style {
    background-color: #95a0ae;
    ul li {
      color: #fff;
    }
  }

  ul {
    display: flex;
    list-style-type: none;
    max-width: 1024px;
    margin: 0 auto;
    padding-left: 20px;
    /* @media screen and (min-width: 1388px) {
      padding-left: 10px;
    } */
    li {
      display: flex;
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 12px;
      cursor: default;
      align-items: center;
      color: #79838e;
      &:last-child {
        text-decoration: underline;
      }
      &:not(:last-child)::after {
        content: '';
        background-image: url('~@/assets/icons/arrow-right.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 26px;
        height: 13px;
      }
      &.linked {
        cursor: pointer;
        font-weight: normal;
        &:hover {
          text-decoration: underline;
        }
      }
      & > span {
        white-space: nowrap;
        overflow: hidden;
        max-width: 330px;
        text-overflow: ellipsis;
        text-transform: capitalize;
      }
    }
  }
}
.image-bg {
  background-image: url('~@/assets/bg/otp_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
</style>
