<template>
  <div id="divCampaignDetailWrapper" v-if="campaign" class="campaign-wrapper">
    <div class="content-row height-35"></div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <a
          v-if="campaign.videoUrl"
          :href="campaign.coverImageLink"
          class="campaign-top-video-container"
        >
          <BrandVimeoPlayer
            class="video-wrapper"
            :id="campaign.videoUrl"
            ref="vimeoPlayer"
            :data="{ embedVideo: campaign.videoUrl }"
          >
          </BrandVimeoPlayer>
        </a>

        <a v-else :href="campaign.coverImageLink" class="campaign-wrapper-image">
          <img v-if="campaign.largeImageURL" :src="campaign.largeImageURL" alt="campaign-image" />
        </a>

        <div class="button-wrapper">
          <BrandButton
            class="campaign-wrapper-image-join"
            v-if="campaign.link"
            :size="sizes.xSmall"
            as="a"
            :target="campaign.link"
          >
            <VueText color="white-100" sizeLevel="4" weightLevel="3">HEMEN KATIL</VueText>
          </BrandButton>
        </div>
      </div>
    </div>
    <div class="content-row">
      <div class="content-layout-fixer">
        <div class="campaign-wrapper-description">
          <div class="description-wrapper">
            <div class="description-title">
              <VueText sizeLevel="9" weightLevel="3">{{ campaign.title }}</VueText>
              <span class="description-date">
                <VueText color="#79838e" sizeLevel="4" weightLevel="3">{{
                  campaign.publishDate | parseISODate
                }}</VueText>
              </span>
            </div>
            <div class="description-content" v-html="htmlText"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Campaign } from '@/services/Api/index';
import { COMPONENT_SIZES } from '@/constants/component.constants.js';
import BrandButton from '@/components/brand/BrandButton/BrandButton';
import VueText from '@/components/shared/VueText/VueText';
import dateUtils from '@/mixins/dateUtils';
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';

export default {
  name: 'CampaignDetail',
  components: {
    VueText,
    BrandButton,
    BrandVimeoPlayer,
  },
  mixins: [dateUtils],
  data() {
    return {
      campaign: {},
      htmlText: '',
    };
  },

  beforeMount() {
    Campaign.getCampaignDetail(this.$route.params.id).then(res => {
      if (res.data && res.data.Data) {
        const {
          Data: {
            campaign: { campaign, htmlText },
          },
        } = res.data;
        this.campaign = campaign;
        this.htmlText = htmlText;
        this.$route.meta.breadcrumb[2].name = this.campaign.title;
      }
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    sizes() {
      return COMPONENT_SIZES;
    },
  },
};
</script>
<style lang="scss">
.content-row {
  height: unset;
  width: 100%;
}
.height-35 {
  background: palette-color(white-100);
  height: 35px;
}

.campaign-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: palette-space-level(20);

  .campaign-wrapper-image {
    position: relative;
    img {
      display: block;
      width: 100%;
    }
  }
  .campaign-wrapper-description {
    .description-wrapper {
      padding: palette-space-level(20);
      .description-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid palette-color-level('grey', 20);
        padding-bottom: palette-space-level(15);
      }
      .description-content {
        padding-top: palette-space-level(15);
      }
    }
  }
}
.campaign-top-video-container {
  position: relative;
}

.button-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: palette-space-level(20);
}

.campaign-wrapper-image-join {
  max-width: 130px;
  max-height: 35px;
}
</style>
