<template>
  <div class="wrapper" @click="goToVirtualVisitUrl">
    <img :src="imgSrc" alt="chatbot" />
    <div class="text-content-wrapper">
      <div class="text-content">
        <VueText sizeLevel="7" weightLevel="5">Şef Dükkanında&nbsp;</VueText>
        <VueText sizeLevel="4" weightLevel="2">izle</VueText>
      </div>
    </div>
    <VueIcon
      v-if="watched"
      iconColor="#d02d2c"
      :width="watchedIcon.width"
      :height="watchedIcon.height"
      :iconName="watchedIcon.name"
      class="watched"
    />
    <div v-else class="badge">1</div>
  </div>
</template>
<script>
import VueText from 'VueText';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import RoutesRoot from '@/router/routes/RoutesRoot';
import RoutesSecure from '@/router/routes/RoutesSecure';
import gtmUtils from '@/mixins/gtmUtils.js';
import { ICON_VARIABLES } from '@/constants/component.constants.js';
import { VirtualVisit } from '@/services/Api/index';

export default {
  name: 'ButtonVirtualVisit',
  components: {
    VueText,
    VueIcon,
  },
  mixins: [gtmUtils],
  data() {
    return {
      watched: true,
    };
  },
  computed: {
    imgSrc() {
      return require('../../../assets/button/virtualvisit-sef.png');
    },
    watchedIcon() {
      return ICON_VARIABLES.checked;
    },
  },
  methods: {
    goToVirtualVisitUrl() {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('sef_dukkaninda', {
        location: 'sidebar',
      });
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.VirtualVisit.path}`);
    },
    getVirtualVisitStatus() {
      VirtualVisit.getVirtualVisitStatus().then(res => {
        this.watched = res.data.Data.isWatch;
      });
    },
  },
  created() {
    this.getVirtualVisitStatus();
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: bottom;
  background: url('~@/assets/button/virtualvisit-bg.png');
  margin-bottom: palette-space-level(10) !important;
  background-repeat: no-repeat;
  background-position-y: bottom;
  cursor: pointer;
  > img {
    margin-left: palette-space-level(8) !important;
  }
  .text-content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: palette-space-level(10);
    padding-left: palette-space-level(10);
    .text-content {
      display: flex;
      flex-direction: column;
      user-select: none;
      color: #fff;
      text-align: center;
    }
  }
  .badge {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: yellow;
    color: #d02d2c;
    margin: palette-space-level(60) auto 0 auto;
  }
  .watched {
    margin: palette-space-level(60) auto 0 auto;
  }
}
</style>
